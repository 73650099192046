import {
  type RuleGroupTypeAny, type RuleGroupType, type ValidationMap, type RuleType,
  type ValidationResult
} from "react-querybuilder";
import { isDayjs } from "dayjs";
import CombinatorTypes from "@enums/combinator-type.enum";
import fields from "@data/field-names-type.data";

const isGroup = (possibleGroup: RuleGroupTypeAny): boolean => Array.isArray(possibleGroup.rules);

const disableMoreOptions = (query: RuleGroupType): boolean => {
  // Validate input data (trust no input data).
  if (!query) {
    return true;
  }

  // Business logic.
  // Check if the query has groups.
  const hasGroups = query.rules.some(rule => isGroup(rule as RuleGroupTypeAny));
  if (hasGroups) {
    return true;
  }

  // Check if the query main parent has the "OR" combinator.
  const parentGroupHasAnyCombinator = query.combinator === CombinatorTypes.Or;

  // Create success output.
  return parentGroupHasAnyCombinator;
};

const queryBuilderRecursiveValidator = (query: RuleGroupType, errors: ValidationMap): void => {
  // Validate input data (trust no input data).
  // Check if the group is empty (has no rules).
  if (Array.isArray(query.rules) && query.rules.length === 0) {
    errors[query.id as string] = { valid: false };
    return;
  }

  // Business logic.
  // Validate rules.
  const rules = query.rules.filter(rule => !isGroup(rule as RuleGroupTypeAny)) as RuleType[];
  if (rules.length > 0) {
    rules.forEach((rule: RuleType): void => {
      // Empty fields validation.
      if (rule.field === "~") {
        errors[rule.id as string] = { valid: false };
        return;
      }

      // Boolean validation - skipped.
      if (typeof (rule.value) === "boolean") {
        return;
      }

      // Date validation.
      if (isDayjs(rule.value) && !rule.value.isValid()) {
        errors[rule.id as string] = { valid: false };
        return;
      }

      // Specific validation for 'carc' field
      if (rule.field === fields.carc.name) {
        const validationResult = validateCarcOrRarc(rule);
        if (!validationResult.valid) {
          errors[rule.id as string] = validationResult;
          return;
        }
      }

      // Specific validation for 'rarc' field
      if (rule.field === fields.rarc.name) {
        const validationResult = validateCarcOrRarc(rule);
        if (!validationResult.valid) {
          errors[rule.id as string] = validationResult;
          return;
        }
      }

      // Specific validation for 'lastInitial'
      if (rule.field === fields.lastInitial.name) {
        const validationResult = validateLastInitial(rule);
        if (!validationResult.valid) {
          errors[rule.id as string] = validationResult;
          return;
        }
      }

      // Empty rules value validation.
      if ((Array.isArray(rule.value) && rule.value.length === 0) || !rule.value) {
        errors[rule.id as string] = { valid: false };
        return;
      }

      // Custom validation for "Payment Likelihood (%)" field
      if (rule.field === fields.paymentLikelihood.name) {
        if (typeof rule.value !== "string") {
          errors[rule.id as string] = { valid: false };
          return;
        }

        if (rule.operator !== "between") {
          const value = Number(rule.value);
          if (isNaN(value) || value < 0 || value > 100) {
            errors[rule.id as string] = { valid: false, reasons: ["Values have to be numbers between 0 and 100."] };
          }

          return;
        }

        const [firstValueStr, secondValueStr] = rule.value.split(",").map(v => v.trim());
        const firstValue = Number(firstValueStr);
        const secondValue = Number(secondValueStr);

        if (
          isNaN(firstValue) || firstValue < 0 || firstValue > 100
          || isNaN(secondValue) || secondValue < 0 || secondValue > 100
          || firstValue >= secondValue
        ) {
          errors[rule.id as string] = { valid: false, reasons: ["Values have to be numbers between 0 and 100."] };
        }
      }
    });
  }

  // Validate groups.
  const groups = query.rules.filter(rule => isGroup(rule as RuleGroupTypeAny)) as RuleGroupType[];
  if (groups.length > 0) {
    groups.forEach((group: RuleGroupType): void => {
      queryBuilderRecursiveValidator(group, errors);
    });
  }
};

const queryBuilderValidator = (query: RuleGroupTypeAny): ValidationMap => {
  // Business logic.
  const errors = {} as const satisfies ValidationMap;
  queryBuilderRecursiveValidator(query as RuleGroupType, errors);

  // Create success output.
  return errors;
};

const isQueryValid = (query: RuleGroupTypeAny | undefined): boolean => {
  // Validate input data (trust no input data).
  if (!query) {
    return false;
  }

  // Business logic.
  const errors = queryBuilderValidator(query);

  // Create success output.
  return Object.keys(errors).length === 0;
};

const validateCarcOrRarc = (rule: RuleType): ValidationResult => {
  const { value } = rule;

  const isAlphanumeric = /^[a-zA-Z0-9]*$/.test(value);
  const isValidLength = value.length <= 10;

  if (isAlphanumeric && isValidLength) {
    return { valid: true };
  }

  const reasons: string[] = [];
  if (!isAlphanumeric) {
    return { valid: false, reasons: ["Value must be alphanumeric."] };
  }

  if (!isValidLength) {
    return { valid: false, reasons: ["Value must not exceed 10 characters."] };
  }

  return { valid: false, reasons };
};

const validateLastInitial = (rule: RuleType): ValidationResult => {
  const { operator, value } = rule;

  const charArray = splitStringToCharArray(value);

  const isValidLetter = (char: string): boolean => /^[a-zA-Z]$/.test(char);

  if (operator === "between") {
    if (charArray.length < 2) {
      return { valid: false, reasons: ["Value must contain at least two characters."] };
    }

    const [firstChar, secondChar] = charArray.slice(0, 2);
    if (!isValidLetter(firstChar ?? "") || !isValidLetter(secondChar ?? "")) {
      return { valid: false, reasons: ["Both characters must be letters."] };
    }

    if ((firstChar ?? "") > (secondChar ?? "")) {
      return { valid: false, reasons: ["Order of letters is not ascending."] };
    }

    return { valid: true };
  }

  if (charArray.length === 0 || !isValidLetter(charArray[0] ?? "")) {
    return { valid: false, reasons: ["Value must contain at least one letter."] };
  }

  return { valid: true };
};

const splitStringToCharArray = (input: string): string[] => {
  if (input.length === 0) {
    return [];
  }

  if (input.includes(",")) {
    return input.split(",").map(str => str.trim());
  }

  return [input[0]!];
};

export { disableMoreOptions, queryBuilderValidator, isQueryValid };
